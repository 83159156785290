@file:UseContextualSerialization(Uuid::class)

package protocol.poker

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import kotlin.uuid.Uuid

@Serializable
data class RingGameSettings(
    val allowStraddle: Boolean,
    val buyIn: RingBuyInSettings,
)

@Serializable
sealed interface RingBuyInSettings {
    @Serializable
    @SerialName("RangeBuyIn")
    data class RangeBuyIn(val minBuyIn: Fixed2, val maxBuyIn: Fixed2) : RingBuyInSettings

    @Serializable
    @SerialName("ImplicitBuyIn")
    data class ImplicitBuyIn(val buyIn: Fixed2) : RingBuyInSettings
}
