package studio.lostjoker.smartdealer.firebase

import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.FirebaseApp

expect val Firebase.analytics: FirebaseAnalytics

expect fun Firebase.analytics(app: FirebaseApp): FirebaseAnalytics

interface FirebaseAnalytics {
    class Param {
        companion object {
            // Generic
            const val ACHIEVEMENT_ID = "achievement_id"
            const val AD_FORMAT = "ad_format"
            const val AD_PLATFORM = "ad_platform"
            const val AD_SOURCE = "ad_source"
            const val AD_UNIT_NAME = "ad_unit_name"
            const val CHARACTER = "character"
            const val TRAVEL_CLASS = "travel_class"
            const val CONTENT_TYPE = "content_type"
            const val CURRENCY = "currency"
            const val COUPON = "coupon"
            const val START_DATE = "start_date"
            const val END_DATE = "end_date"
            const val EXTEND_SESSION = "extend_session"
            const val FLIGHT_NUMBER = "flight_number"
            const val GROUP_ID = "group_id"
            const val ITEM_CATEGORY = "item_category"
            const val ITEM_ID = "item_id"
            const val ITEM_NAME = "item_name"
            const val LOCATION = "location"
            const val LEVEL = "level"
            const val LEVEL_NAME = "level_name"
            const val METHOD = "method"
            const val NUMBER_OF_NIGHTS = "number_of_nights"
            const val NUMBER_OF_PASSENGERS = "number_of_passengers"
            const val NUMBER_OF_ROOMS = "number_of_rooms"
            const val DESTINATION = "destination"
            const val ORIGIN = "origin"
            const val PRICE = "price"
            const val QUANTITY = "quantity"
            const val SCORE = "score"
            const val SHIPPING = "shipping"
            const val TRANSACTION_ID = "transaction_id"
            const val SEARCH_TERM = "search_term"
            const val SUCCESS = "success"
            const val TAX = "tax"
            const val VALUE = "value"
            const val VIRTUAL_CURRENCY_NAME = "virtual_currency_name"
            const val CAMPAIGN = "campaign"
            const val SOURCE = "source"
            const val MEDIUM = "medium"
            const val TERM = "term"
            const val CONTENT = "content"
            const val ACLID = "aclid"
            const val CP1 = "cp1"
            const val CAMPAIGN_ID = "campaign_id"
            const val SOURCE_PLATFORM = "source_platform"
            const val CREATIVE_FORMAT = "creative_format"
            const val MARKETING_TACTIC = "marketing_tactic"
            const val ITEM_BRAND = "item_brand"
            const val ITEM_VARIANT = "item_variant"
            const val CREATIVE_NAME = "creative_name"
            const val CREATIVE_SLOT = "creative_slot"
            const val AFFILIATION = "affiliation"
            const val INDEX = "index"
            const val DISCOUNT = "discount"
            const val ITEM_CATEGORY2 = "item_category2"
            const val ITEM_CATEGORY3 = "item_category3"
            const val ITEM_CATEGORY4 = "item_category4"
            const val ITEM_CATEGORY5 = "item_category5"
            const val ITEM_LIST_ID = "item_list_id"
            const val ITEM_LIST_NAME = "item_list_name"
            const val ITEMS = "items"
            const val LOCATION_ID = "location_id"
            const val PAYMENT_TYPE = "payment_type"
            const val PROMOTION_ID = "promotion_id"
            const val PROMOTION_NAME = "promotion_name"
            const val SCREEN_CLASS = "screen_class"
            const val SCREEN_NAME = "screen_name"
            const val SHIPPING_TIER = "shipping_tier"
            const val ERROR = "error"

            // Join & Host Game
            const val DEVICE_TYPE = "device_type"
            const val GAME_ID = "game_id"
            const val GAME_CODE = "game_code"
            const val GAME_FORMAT = "game_format"
            const val GAME_VARIANT = "game_variant"
            const val TABLE_SIZE = "table_size"
            const val BUY_IN = "buy_in"
            const val BUY_IN_RANGE = "buy_in_range"
            const val STARTING_STACK = "starting_stack"
            const val BLIND_LEVEL_UP = "blind_level_up"
            const val BLIND_SPEED = "blind_speed"
            const val BLIND_SETTINGS = "blind_settings"
            const val PAYOUT_STRUCTURE = "payout_structure"
            const val LATE_REGISTRATION_DURATION = "late_registration_duration"
            const val REBUY_ENABLED = "rebuy_enabled"
            const val REBUY_LIMIT = "rebuy_limit"
            const val REBUY_DURATION = "rebuy_duration"

            // Create Game
            const val TABLE_ID = "table_id"

            // Register Player
            const val PLAYER_ID = "player_id"

            // Start Game
            const val NUMBER_OF_PLAYERS = "number_of_players"
            const val NUMBER_OF_BOTS = "number_of_bots"
            const val GAME_STARTED = "game_started"

            // Game Complete
            const val GAME_ENDED = "game_ended"
            const val GAME_TIME = "game_time"
            const val HANDS_PLAYED = "hands_played"

            // Game Replay
            const val REPLAY_SELECTED = "replay_selected"

            // Game Overview
            const val OVERVIEW_SELECTED = "overview_selected"

            // Game Settings
            const val VERTICAL_DISPLAY = "vertical_display"
            const val TABLE_LAYOUT = "table_layout"
            const val TABLE_FELT = "table_felt"
            const val CARD_STYLE = "card_style"
            const val CARD_BACK_STYLE = "card_back_style"

            // Game Help
            const val HELP_SELECTED = "help_selected"

            // Tournament Summary
            const val RANK = "rank"
            const val PRIZE = "prize"
            const val REBUY_QUANTITY = "rebuy_quantity"

            // Tutorial
            const val TUTORIAL_TYPE = "tutorial_type"

            // Login
            const val GUEST = "guest"
        }
    }

    class Event {
        companion object {
            // Generic
            const val AD_IMPRESSION = "ad_impression"
            const val ADD_PAYMENT_INFO = "add_payment_info"
            const val ADD_TO_CART = "add_to_cart"
            const val ADD_TO_WISHLIST = "add_to_wishlist"
            const val APP_OPEN = "app_open"
            const val BEGIN_CHECKOUT = "begin_checkout"
            const val CAMPAIGN_DETAILS = "campaign_details"
            const val GENERATE_LEAD = "generate_lead"
            const val JOIN_GROUP = "join_group"
            const val LEVEL_END = "level_end"
            const val LEVEL_START = "level_start"
            const val LEVEL_UP = "level_up"
            const val LOGIN = "login"
            const val LOGOUT = "logout"
            const val POST_SCORE = "post_score"
            const val SEARCH = "search"
            const val SELECT_CONTENT = "select_content"
            const val SHARE = "share"
            const val SIGN_UP = "sign_up"
            const val SPEND_VIRTUAL_CURRENCY = "spend_virtual_currency"
            const val TUTORIAL_BEGIN = "tutorial_begin"
            const val TUTORIAL_COMPLETE = "tutorial_complete"
            const val TUTORIAL_SKIP = "tutorial_skip"
            const val UNLOCK_ACHIEVEMENT = "unlock_achievement"
            const val VIEW_ITEM = "view_item"
            const val VIEW_ITEM_LIST = "view_item_list"
            const val VIEW_SEARCH_RESULTS = "view_search_results"
            const val EARN_VIRTUAL_CURRENCY = "earn_virtual_currency"
            const val SCREEN_VIEW = "screen_view"
            const val REMOVE_FROM_CART = "remove_from_cart"
            const val ADD_SHIPPING_INFO = "add_shipping_info"
            const val PURCHASE = "purchase"
            const val REFUND = "refund"
            const val SELECT_ITEM = "select_item"
            const val SELECT_PROMOTION = "select_promotion"
            const val VIEW_CART = "view_cart"
            const val VIEW_PROMOTION = "view_promotion"

            // Game
            const val JOIN_GAME = "join_game"
            const val HOST_GAME = "host_game"
            const val REGISTER_PLAYER = "register_player"
            const val PLAYER_BUY_IN = "player_buy_in"
            const val CHANGE_PLAYER_SEAT = "change_player_seat"
            const val START_GAME = "start_game"
            const val PAUSE_GAME = "pause_game"
            const val RESUME_GAME = "resume_game"
            const val GAME_COMPLETE = "game_complete"
            const val LEAVE_GAME = "leave_game"
            const val CHECK_GAME_REPLAY = "check_game_replay"
            const val CHECK_GAME_OVERVIEW = "check_game_overview"
            const val CHANGE_GAME_SETTINGS = "change_game_settings"
            const val REQUEST_GAME_HELP = "request_game_help"
            const val SCREENSHOT_TOURNAMENT_SUMMARY = "screenshot_tournament_summary"
            const val SCREENSHOT_RING_SUMMARY = "screenshot_ring_summary"
            const val SHARE_GAME_CODE = "share_game_code"

            // Profile
            const val UPDATE_SCREEN_NAME = "update_screen_name"
            const val UPDATE_AVATAR = "update_avatar"
            const val LINK_ACCOUNT = "link_account"
            const val INVITE_FRIENDS = "invite_friends"
            const val CONTACT_US = "contact_us"
            const val PRIVACY_POLICY = "privacy_policy"
            const val DELETE_ACCOUNT = "delete_account"
        }
    }

    fun logEvent(eventName: String, parameters: List<FirebaseParamsBuilder.Parameter>)
}

class FirebaseParamsBuilder {
    sealed interface Parameter {
        val name: String
    }

    class LongParameter(override val name: String, val value: Long) : Parameter
    class StringParameter(override val name: String, val value: String) : Parameter
    class DoubleParameter(override val name: String, val value: Double) : Parameter
}
