@file:UseContextualSerialization(Uuid::class)

package studio.lostjoker.smartdealer.domain.poker.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import studio.lostjoker.smartdealer.domain.Fixed2
import studio.lostjoker.smartdealer.domain.toFixed2
import kotlin.uuid.Uuid

@Serializable
data class RingGameSettings(
    val allowStraddle: Boolean,
    val buyIn: RingBuyInSettings,
) {
    companion object {
        internal val Empty = RingGameSettings(
            allowStraddle = false,
            buyIn = RingBuyInSettings.ImplicitBuyIn(0.toFixed2()),
        )
    }
}

@Serializable
sealed interface RingBuyInSettings {
    @Serializable
    @SerialName("RangeBuyIn")
    data class RangeBuyIn(val minBuyIn: Fixed2, val maxBuyIn: Fixed2) : RingBuyInSettings

    @Serializable
    @SerialName("ImplicitBuyIn")
    data class ImplicitBuyIn(val buyIn: Fixed2) : RingBuyInSettings
}
