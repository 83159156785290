package studio.lostjoker.smartdealer.ui.components

import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.LocalContentColor
import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector

@Composable
fun TopAppBarActionButton(
    imageVector: ImageVector,
    description: String,
    color: Color = LocalContentColor.current,
    onClick: () -> Unit,
) =
    IconButton(
        onClick = {
            onClick()
        },
    ) {
        Icon(imageVector = imageVector, contentDescription = description, tint = color)
    }
