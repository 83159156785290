@file:UseContextualSerialization(Uuid::class)

package protocol

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import kotlin.uuid.Uuid

@Serializable
sealed interface Seat {
    val seat: Int

    @Serializable
    @SerialName("Occupied")
    data class Occupied(
        override val seat: Int,
        val player: Player,
        val away: Boolean,
    ) : Seat
}

@Serializable
sealed interface Player {
    val id: String

    @Serializable
    @SerialName("Human")
    data class Human(val userId: String) : Player {
        override val id: String
            get() = userId
    }

    @Serializable
    @SerialName("Bot")
    data class Bot(val botId: String) : Player {
        override val id: String
            get() = botId
    }
}
