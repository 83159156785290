package studio.lostjoker.smartdealer.gameplay.protocol.converter

import studio.lostjoker.smartdealer.domain.poker.game.projection.TournamentCreatedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.TournamentSettingsUpdatedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerRegisteredEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerRebuyCompletedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerStackUpdatedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.GameStartedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.GamePausedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.GameResumedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.BlindLevelChangedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerEliminatedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.GameEndedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.TableCreatedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.TableSettingsUpdatedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerSeatedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerSatOutEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerSatInEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.HandStartedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.RoundStartedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.ActionRequestedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.ActionPerformedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.BlindsPostedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerCardsDealtEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerCardsRevealedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.CommunityCardsDealtEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.RoundEndedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.HandEndedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.RingCreatedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerBoughtInEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerToppedUpEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.RingGameSettingsUpdatedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerBlindMissedEvent

// converters for TournamentCreatedEvent
fun TournamentCreatedEvent.toProtocol() = protocol.poker.TournamentCreatedEvent(
	hostPlayerId = hostPlayerId,
	format = format.toProtocol(),
	settings = settings.toProtocol(),
)
fun protocol.poker.TournamentCreatedEvent.toDomain() = TournamentCreatedEvent(
	hostPlayerId = hostPlayerId,
	format = format.toDomain(),
	settings = settings.toDomain(),
)

// converters for TournamentSettingsUpdatedEvent
fun TournamentSettingsUpdatedEvent.toProtocol() = protocol.poker.TournamentSettingsUpdatedEvent(
	format = format.toProtocol(),
	settings = settings.toProtocol(),
	rebuyRemainingCount = rebuyRemainingCount,
)
fun protocol.poker.TournamentSettingsUpdatedEvent.toDomain() = TournamentSettingsUpdatedEvent(
	format = format.toDomain(),
	settings = settings.toDomain(),
	rebuyRemainingCount = rebuyRemainingCount,
)

// converters for PlayerRegisteredEvent
fun PlayerRegisteredEvent.toProtocol() = protocol.poker.PlayerRegisteredEvent(
	player = player.toProtocol(),
	sessionId = sessionId,
)
fun protocol.poker.PlayerRegisteredEvent.toDomain() = PlayerRegisteredEvent(
	player = player.toDomain(),
	sessionId = sessionId,
)

// converters for PlayerRebuyCompletedEvent
fun PlayerRebuyCompletedEvent.toProtocol() = protocol.poker.PlayerRebuyCompletedEvent(
	sessionId = sessionId,
	amount = amount.toProtocol(),
	rebuyRemainingCount = rebuyRemainingCount,
)
fun protocol.poker.PlayerRebuyCompletedEvent.toDomain() = PlayerRebuyCompletedEvent(
	sessionId = sessionId,
	amount = amount.toDomain(),
	rebuyRemainingCount = rebuyRemainingCount,
)

// converters for PlayerStackUpdatedEvent
fun PlayerStackUpdatedEvent.toProtocol() = protocol.poker.PlayerStackUpdatedEvent(
	tableId = tableId,
	playerId = playerId,
	seat = seat,
	amount = amount.toProtocol(),
	stack = stack.toProtocol(),
)
fun protocol.poker.PlayerStackUpdatedEvent.toDomain() = PlayerStackUpdatedEvent(
	tableId = tableId,
	playerId = playerId,
	seat = seat,
	amount = amount.toDomain(),
	stack = stack.toDomain(),
)

// converters for GameStartedEvent
fun GameStartedEvent.toProtocol() = protocol.poker.GameStartedEvent(
	instant = instant,
)
fun protocol.poker.GameStartedEvent.toDomain() = GameStartedEvent(
	instant = instant,
)

// converters for GamePausedEvent
fun GamePausedEvent.toProtocol() = protocol.poker.GamePausedEvent(
	instant = instant,
)
fun protocol.poker.GamePausedEvent.toDomain() = GamePausedEvent(
	instant = instant,
)

// converters for GameResumedEvent
fun GameResumedEvent.toProtocol() = protocol.poker.GameResumedEvent(
	instant = instant,
)
fun protocol.poker.GameResumedEvent.toDomain() = GameResumedEvent(
	instant = instant,
)

// converters for BlindLevelChangedEvent
fun BlindLevelChangedEvent.toProtocol() = protocol.poker.BlindLevelChangedEvent(
	blindLevel = blindLevel.toProtocol(),
	handCountRange = handCountRange.toProtocol(),
	timeSlotDuration = timeSlotDuration,
	lateRegistrationLevelsLeft = lateRegistrationLevelsLeft,
	rebuyLevelsLeft = rebuyLevelsLeft,
	instant = instant,
)
fun protocol.poker.BlindLevelChangedEvent.toDomain() = BlindLevelChangedEvent(
	blindLevel = blindLevel.toDomain(),
	handCountRange = handCountRange.toDomain(),
	timeSlotDuration = timeSlotDuration,
	lateRegistrationLevelsLeft = lateRegistrationLevelsLeft,
	rebuyLevelsLeft = rebuyLevelsLeft,
	instant = instant,
)

// converters for PlayerEliminatedEvent
fun PlayerEliminatedEvent.toProtocol() = protocol.poker.PlayerEliminatedEvent(
	sessionId = sessionId,
	rank = rank,
	prize = prize.toProtocol(),
	rebuy = rebuy,
	finalStack = finalStack.toProtocol(),
)
fun protocol.poker.PlayerEliminatedEvent.toDomain() = PlayerEliminatedEvent(
	sessionId = sessionId,
	rank = rank,
	prize = prize.toDomain(),
	rebuy = rebuy,
	finalStack = finalStack.toDomain(),
)

// converters for GameEndedEvent
fun GameEndedEvent.toProtocol() = protocol.poker.GameEndedEvent(
	instant = instant,
)
fun protocol.poker.GameEndedEvent.toDomain() = GameEndedEvent(
	instant = instant,
)

// converters for TableCreatedEvent
fun TableCreatedEvent.toProtocol() = protocol.poker.TableCreatedEvent(
	tableId = tableId,
	settings = settings.toProtocol(),
	straddle = straddle,
)
fun protocol.poker.TableCreatedEvent.toDomain() = TableCreatedEvent(
	tableId = tableId,
	settings = settings.toDomain(),
	straddle = straddle,
)

// converters for TableSettingsUpdatedEvent
fun TableSettingsUpdatedEvent.toProtocol() = protocol.poker.TableSettingsUpdatedEvent(
	tableId = tableId,
	settings = settings.toProtocol(),
)
fun protocol.poker.TableSettingsUpdatedEvent.toDomain() = TableSettingsUpdatedEvent(
	tableId = tableId,
	settings = settings.toDomain(),
)

// converters for PlayerSeatedEvent
fun PlayerSeatedEvent.toProtocol() = protocol.poker.PlayerSeatedEvent(
	tableId = tableId,
	player = player.toProtocol(),
	seat = seat,
	stack = stack.toProtocol(),
)
fun protocol.poker.PlayerSeatedEvent.toDomain() = PlayerSeatedEvent(
	tableId = tableId,
	player = player.toDomain(),
	seat = seat,
	stack = stack.toDomain(),
)

// converters for PlayerSatOutEvent
fun PlayerSatOutEvent.toProtocol() = protocol.poker.PlayerSatOutEvent(
	tableId = tableId,
	seat = seat,
	reason = reason.toProtocol(),
)
fun protocol.poker.PlayerSatOutEvent.toDomain() = PlayerSatOutEvent(
	tableId = tableId,
	seat = seat,
	reason = reason.toDomain(),
)

// converters for PlayerSatInEvent
fun PlayerSatInEvent.toProtocol() = protocol.poker.PlayerSatInEvent(
	tableId = tableId,
	seat = seat,
)
fun protocol.poker.PlayerSatInEvent.toDomain() = PlayerSatInEvent(
	tableId = tableId,
	seat = seat,
)

// converters for HandStartedEvent
fun HandStartedEvent.toProtocol() = protocol.poker.HandStartedEvent(
	tableId = tableId,
	handId = handId,
	handIndex = handIndex,
	positions = positions.toProtocol(),
	settings = settings.toProtocol(),
)
fun protocol.poker.HandStartedEvent.toDomain() = HandStartedEvent(
	tableId = tableId,
	handId = handId,
	handIndex = handIndex,
	positions = positions.toDomain(),
	settings = settings.toDomain(),
)

// converters for RoundStartedEvent
fun RoundStartedEvent.toProtocol() = protocol.poker.RoundStartedEvent(
	roundIndex = roundIndex,
	potSizeBeforeRound = potSizeBeforeRound.toProtocol(),
	remainingStacks = remainingStacks.map { it.toProtocol() },
)
fun protocol.poker.RoundStartedEvent.toDomain() = RoundStartedEvent(
	roundIndex = roundIndex,
	potSizeBeforeRound = potSizeBeforeRound.toDomain(),
	remainingStacks = remainingStacks.map { it.toDomain() },
)

// converters for ActionRequestedEvent
fun ActionRequestedEvent.toProtocol() = protocol.poker.ActionRequestedEvent(
	requestId = requestId,
	seat = seat,
	availableActions = availableActions.map { it.toProtocol() },
	instant = instant,
	timeout = timeout,
)
fun protocol.poker.ActionRequestedEvent.toDomain() = ActionRequestedEvent(
	requestId = requestId,
	seat = seat,
	availableActions = availableActions.map { it.toDomain() }.toSet(),
	instant = instant,
	timeout = timeout,
)

// converters for ActionPerformedEvent
fun ActionPerformedEvent.toProtocol() = protocol.poker.ActionPerformedEvent(
	seat = seat,
	action = action.toProtocol(),
	requestId = requestId,
	bettingStack = bettingStack.toProtocol(),
	remainingStack = remainingStack.toProtocol(),
	totalPotSize = totalPotSize.toProtocol(),
	auto = auto,
	away = away,
)
fun protocol.poker.ActionPerformedEvent.toDomain() = ActionPerformedEvent(
	seat = seat,
	action = action.toDomain(),
	requestId = requestId,
	bettingStack = bettingStack.toDomain(),
	remainingStack = remainingStack.toDomain(),
	totalPotSize = totalPotSize.toDomain(),
	auto = auto,
	away = away,
)

// converters for BlindsPostedEvent
fun BlindsPostedEvent.toProtocol() = protocol.poker.BlindsPostedEvent
fun protocol.poker.BlindsPostedEvent.toDomain() = BlindsPostedEvent

// converters for PlayerCardsDealtEvent
fun PlayerCardsDealtEvent.toProtocol() = protocol.poker.PlayerCardsDealtEvent(
	tableId = tableId,
	handId = handId,
	seat = seat,
	cards = cards.map { it.toProtocol() },
)
fun protocol.poker.PlayerCardsDealtEvent.toDomain() = PlayerCardsDealtEvent(
	tableId = tableId,
	handId = handId,
	seat = seat,
	cards = cards.map { it.toDomain() },
)

// converters for PlayerCardsRevealedEvent
fun PlayerCardsRevealedEvent.toProtocol() = protocol.poker.PlayerCardsRevealedEvent(
	tableId = tableId,
	handId = handId,
	seat = seat,
	cards = cards.map { it.toProtocol() },
)
fun protocol.poker.PlayerCardsRevealedEvent.toDomain() = PlayerCardsRevealedEvent(
	tableId = tableId,
	handId = handId,
	seat = seat,
	cards = cards.map { it.toDomain() },
)

// converters for CommunityCardsDealtEvent
fun CommunityCardsDealtEvent.toProtocol() = protocol.poker.CommunityCardsDealtEvent(
	tableId = tableId,
	handId = handId,
	cards = cards.map { it.toProtocol() },
	highHandRanks = highHandRanks.mapValues { it.value.toProtocol() },
	lowHandRanks = lowHandRanks.mapValues { it.value.toProtocol() },
)
fun protocol.poker.CommunityCardsDealtEvent.toDomain() = CommunityCardsDealtEvent(
	tableId = tableId,
	handId = handId,
	cards = cards.map { it.toDomain() },
	highHandRanks = highHandRanks.mapValues { it.value.toDomain() },
	lowHandRanks = lowHandRanks.mapValues { it.value.toDomain() },
)

// converters for RoundEndedEvent
fun RoundEndedEvent.toProtocol() = protocol.poker.RoundEndedEvent(
	tableId = tableId,
	handId = handId,
	roundIndex = roundIndex,
	seatResults = seatResults.map { it.toProtocol() },
	potContributions = potContributions.map { it.toProtocol() },
)
fun protocol.poker.RoundEndedEvent.toDomain() = RoundEndedEvent(
	tableId = tableId,
	handId = handId,
	roundIndex = roundIndex,
	seatResults = seatResults.map { it.toDomain() },
	potContributions = potContributions.map { it.toDomain() },
)

// converters for HandEndedEvent
fun HandEndedEvent.toProtocol() = protocol.poker.HandEndedEvent(
	tableId = tableId,
	handId = handId,
	seatResults = seatResults.map { it.toProtocol() },
	potShares = potShares.map { it.toProtocol() },
	pots = pots.map { it.toProtocol() },
)
fun protocol.poker.HandEndedEvent.toDomain() = HandEndedEvent(
	tableId = tableId,
	handId = handId,
	seatResults = seatResults.map { it.toDomain() },
	potShares = potShares.map { it.toDomain() },
	pots = pots.map { it.toDomain() },
)

// converters for RingCreatedEvent
fun RingCreatedEvent.toProtocol() = protocol.poker.RingCreatedEvent(
	hostPlayerId = hostPlayerId,
	format = format.toProtocol(),
	settings = settings.toProtocol(),
)
fun protocol.poker.RingCreatedEvent.toDomain() = RingCreatedEvent(
	hostPlayerId = hostPlayerId,
	format = format.toDomain(),
	settings = settings.toDomain(),
)

// converters for PlayerBoughtInEvent
fun PlayerBoughtInEvent.toProtocol() = protocol.poker.PlayerBoughtInEvent(
	player = player.toProtocol(),
	sessionId = sessionId,
)
fun protocol.poker.PlayerBoughtInEvent.toDomain() = PlayerBoughtInEvent(
	player = player.toDomain(),
	sessionId = sessionId,
)

// converters for PlayerToppedUpEvent
fun PlayerToppedUpEvent.toProtocol() = protocol.poker.PlayerToppedUpEvent(
	sessionId = sessionId,
	amount = amount.toProtocol(),
)
fun protocol.poker.PlayerToppedUpEvent.toDomain() = PlayerToppedUpEvent(
	sessionId = sessionId,
	amount = amount.toDomain(),
)

// converters for RingGameSettingsUpdatedEvent
fun RingGameSettingsUpdatedEvent.toProtocol() = protocol.poker.RingGameSettingsUpdatedEvent(
	format = format.toProtocol(),
	settings = settings.toProtocol(),
)
fun protocol.poker.RingGameSettingsUpdatedEvent.toDomain() = RingGameSettingsUpdatedEvent(
	format = format.toDomain(),
	settings = settings.toDomain(),
)

// converters for PlayerBlindMissedEvent
fun PlayerBlindMissedEvent.toProtocol() = protocol.poker.PlayerBlindMissedEvent(
	tableId = tableId,
	seat = seat,
	blindType = blindType.toProtocol(),
)
fun protocol.poker.PlayerBlindMissedEvent.toDomain() = PlayerBlindMissedEvent(
	tableId = tableId,
	seat = seat,
	blindType = blindType.toDomain(),
)

