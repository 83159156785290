package protocol.binary

import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.SerialFormat
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.contextual
import kotlinx.serialization.protobuf.ProtoBuf
import studio.lostjoker.smartdealer.serialization.IntClosedRangeStringSerializer
import studio.lostjoker.smartdealer.serialization.UuidByteArraySerializer
import kotlin.uuid.Uuid

@OptIn(ExperimentalSerializationApi::class)
val pokerGameplayWebsocketSerialFormat: SerialFormat = ProtoBuf {
    serializersModule = SerializersModule {
        contextual(Uuid::class, UuidByteArraySerializer)
        contextual(IntClosedRangeStringSerializer) // TODO review to binary
    }
}
