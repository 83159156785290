@file:UseContextualSerialization(Uuid::class)

package studio.lostjoker.smartdealer.game_management.web.contract

import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import kotlin.uuid.Uuid

@Serializable
data class GameCreationResponse(
    val gameCode: String,
    val gameId: Uuid,
)

@Serializable
data class GameExistsResponse(
    val gameCode: String,
    val gameId: Uuid,
)

@Serializable
data class GameManagementApiError(
    val errorCode: GameManagementApiErrorCode? = null,
)

@Serializable
data class PlayerAccountResponse(
    val playerId: String,
    val screenName: String,
    val emailAddress: String?,
    val hasAvatar: Boolean,
)

@Serializable
enum class AvatarSource {
    Social,
    Custom,
}

@Serializable
data class AvatarUploadRequest(
    val avatarSource: AvatarSource,
    @Contextual
    val avatarBlob: ByteArray,
)
