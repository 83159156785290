package studio.lostjoker.smartdealer.gameplay.protocol.converter

import studio.lostjoker.smartdealer.domain.Suit
import studio.lostjoker.smartdealer.domain.Rank
import studio.lostjoker.smartdealer.domain.DealtRankedCardPlayerView

// converters for Suit
fun Suit.toProtocol(): protocol.Suit = when (this) {
	Suit.Diamonds -> protocol.Suit.Diamonds
	Suit.Spades -> protocol.Suit.Spades
	Suit.Hearts -> protocol.Suit.Hearts
	Suit.Clubs -> protocol.Suit.Clubs
}
fun protocol.Suit.toDomain(): Suit = when (this) {
	protocol.Suit.Diamonds -> Suit.Diamonds
	protocol.Suit.Spades -> Suit.Spades
	protocol.Suit.Hearts -> Suit.Hearts
	protocol.Suit.Clubs -> Suit.Clubs
}

// converters for Rank
fun Rank.toProtocol(): protocol.Rank = when (this) {
	Rank.Two -> protocol.Rank.Two
	Rank.Three -> protocol.Rank.Three
	Rank.Four -> protocol.Rank.Four
	Rank.Five -> protocol.Rank.Five
	Rank.Six -> protocol.Rank.Six
	Rank.Seven -> protocol.Rank.Seven
	Rank.Eight -> protocol.Rank.Eight
	Rank.Nine -> protocol.Rank.Nine
	Rank.Ten -> protocol.Rank.Ten
	Rank.J -> protocol.Rank.J
	Rank.Q -> protocol.Rank.Q
	Rank.K -> protocol.Rank.K
	Rank.A -> protocol.Rank.A
}
fun protocol.Rank.toDomain(): Rank = when (this) {
	protocol.Rank.Two -> Rank.Two
	protocol.Rank.Three -> Rank.Three
	protocol.Rank.Four -> Rank.Four
	protocol.Rank.Five -> Rank.Five
	protocol.Rank.Six -> Rank.Six
	protocol.Rank.Seven -> Rank.Seven
	protocol.Rank.Eight -> Rank.Eight
	protocol.Rank.Nine -> Rank.Nine
	protocol.Rank.Ten -> Rank.Ten
	protocol.Rank.J -> Rank.J
	protocol.Rank.Q -> Rank.Q
	protocol.Rank.K -> Rank.K
	protocol.Rank.A -> Rank.A
}

// converters for DealtRankedCardPlayerView
fun DealtRankedCardPlayerView.FaceDown.toProtocol() = protocol.DealtRankedCardPlayerView.FaceDown
fun protocol.DealtRankedCardPlayerView.FaceDown.toDomain() = DealtRankedCardPlayerView.FaceDown

fun DealtRankedCardPlayerView.FaceUp.toProtocol() = protocol.DealtRankedCardPlayerView.FaceUp(
	card = card.toProtocol(),
)
fun protocol.DealtRankedCardPlayerView.FaceUp.toDomain() = DealtRankedCardPlayerView.FaceUp(
	card = card.toDomain(),
)

fun DealtRankedCardPlayerView.toProtocol(): protocol.DealtRankedCardPlayerView = when (this) {
	is DealtRankedCardPlayerView.FaceDown -> toProtocol()
	is DealtRankedCardPlayerView.FaceUp -> toProtocol()
}
fun protocol.DealtRankedCardPlayerView.toDomain(): DealtRankedCardPlayerView = when (this) {
	is protocol.DealtRankedCardPlayerView.FaceDown -> toDomain()
	is protocol.DealtRankedCardPlayerView.FaceUp -> toDomain()
}

