package studio.lostjoker.smartdealer.domain

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


sealed interface Seat {
    data object Empty : Seat
    data class Occupied(val player: Player, val away: Boolean = false) : Seat
}

@Serializable
sealed interface Player {
    val id: String

    @Serializable
    @SerialName("Human")
    data class Human(val userId: String) : Player {
        override val id: String
            get() = userId
    }

    @Serializable
    @SerialName("Bot")
    data class Bot(val botId: String) : Player {
        override val id: String
            get() = botId
    }
}
