@file:UseContextualSerialization(Uuid::class)

package protocol.poker

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import kotlin.uuid.Uuid


@Serializable
sealed interface PokerTableCommand

@Serializable
sealed interface PokerPlayerCommand

@Serializable
@SerialName("CreateTournament")
data class CreateTournamentCommand(
    override val hostPlayerId: String,
    val tournamentSettings: TournamentSettings,
    val pokerSettings: PokerSettings,
) : PokerPlayerCommand, PokerTableCommand, CreatePokerGameCommand

@Serializable
@SerialName("UpdateTournamentSettings")
data class UpdateTournamentSettingsCommand(
    val tournamentSettings: TournamentSettings,
    val pokerSettings: PokerSettings,
) : PokerPlayerCommand, PokerTableCommand

@Serializable
@SerialName("RegisterPlayer")
data class RegisterPlayerCommand(
    val playerId: String,
    val sessionId: Uuid,
) : PokerPlayerCommand

@Serializable
@SerialName("RegisterBot")
data class RegisterBotCommand(
    val botId: String,
    val sessionId: Uuid,
) : PokerPlayerCommand, PokerTableCommand

@Serializable
@SerialName("UnregisterPlayer")
data class ChangePlayerSeatCommand(
    val playerId: String,
    val seat: Int,
): PokerPlayerCommand

@Serializable
@SerialName("SitInPlayer")
data class SitInPlayerCommand(
    val tableId: Uuid,
    val playerId: String,
    val seat: Int,
) : PokerPlayerCommand

@Serializable
@SerialName("SitOutPlayer")
data class SitOutPlayerCommand(
    val tableId: Uuid,
    val playerId: String,
    val seat: Int,
) : PokerPlayerCommand

@Serializable
@SerialName("StartGame")
data object StartGameCommand : PokerPlayerCommand, PokerTableCommand

@Serializable
@SerialName("PauseGame")
data object PauseGameCommand : PokerPlayerCommand, PokerTableCommand

@Serializable
@SerialName("ResumeGame")
data object ResumeGameCommand : PokerPlayerCommand, PokerTableCommand

@Serializable
@SerialName("DealHand")
class DealHandCommand(
    val tableId: Uuid,
) : PokerPlayerCommand, PokerTableCommand

@Serializable
@SerialName("Play")
data class PlayCommand(
    val tableId: Uuid,
    val requestId: Uuid,
    val action: PlayerHandAction,
): PokerPlayerCommand

@Serializable
@SerialName("RevealPlayerCards")
data class RevealPlayerCardsCommand(
    val tableId: Uuid,
    val seat: Int,
    val cardIndexes: List<Int>,
): PokerPlayerCommand

@Serializable
@SerialName("Rebuy")
data class RebuyCommand(
    val playerId: String,
): PokerPlayerCommand

@Serializable
@SerialName("CreateRingGame")
data class CreateRingGameCommand(
    override val hostPlayerId: String,
    val ringGameSettings: RingGameSettings,
    val pokerSettings: PokerSettings,
) : PokerPlayerCommand, PokerTableCommand, CreatePokerGameCommand

@Serializable
@SerialName("BuyInPlayer")
data class BuyInPlayerCommand(
    val playerId: String,
    val sessionId: Uuid,
) : PokerPlayerCommand

@Serializable
@SerialName("BuyInBot")
data class BuyInBotCommand(
    val botId: String,
    val sessionId: Uuid,
) : PokerPlayerCommand, PokerTableCommand

@Serializable
@SerialName("TopUp")
data class TopUpCommand(
    val playerId: String,
    val amount: Fixed2,
): PokerPlayerCommand

@Serializable
@SerialName("UpdateRingGameSettings")
data class UpdateRingGameSettingsCommand(
    val ringGameSettings: RingGameSettings,
    val pokerSettings: PokerSettings,
) : PokerPlayerCommand, PokerTableCommand

@Serializable
@SerialName("TerminateRing")
data object TerminateRingCommand : PokerPlayerCommand, PokerTableCommand
