@file:UseContextualSerialization(Uuid::class, ClosedRange::class)

package studio.lostjoker.smartdealer.domain.poker.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import studio.lostjoker.smartdealer.domain.Fixed2
import studio.lostjoker.smartdealer.domain.toFixed2
import kotlin.uuid.Uuid


@Serializable
data class TournamentSettings(
    val minPlayers: Int,
    val maxPlayers: Int,
    val startTrigger: TournamentStartTrigger,
    val registrationStack: Fixed2,
    val registration: TournamentRegistrationSettings,
    val lateRegistration: TournamentLateRegistration,
    val rebuy: TournamentRebuyConfig,
    val prize: TournamentPrizeSettings,
) {
    companion object {
        internal val Empty = TournamentSettings(
            minPlayers = 0,
            maxPlayers = 0,
            startTrigger = TournamentStartTrigger.Manual,
            registrationStack = 0.toFixed2(),
            registration = TournamentRegistrationSettings.ImplicitBuyIn(0.toFixed2()),
            lateRegistration = TournamentLateRegistration.Disabled,
            rebuy = TournamentRebuyConfig.Disabled,
            prize = TournamentPrizeSettings.Payout(emptyMap()),
        )
    }
}

@Serializable
sealed interface TournamentStartTrigger {
    @Serializable
    @SerialName("Manual")
    data object Manual : TournamentStartTrigger

    // we may have OnDemand, Scheduled
}

@Serializable
sealed interface TournamentRegistrationSettings {
    @Serializable
    @SerialName("ImplicitBuyIn")
    data class ImplicitBuyIn(
        val buyIn: Fixed2,
    ) : TournamentRegistrationSettings
}

@Serializable
sealed interface TournamentLateRegistration {
    @Serializable
    @SerialName("Disabled")
    data object Disabled : TournamentLateRegistration

    @Serializable
    @SerialName("BlindLevels")
    data class BlindLevels(val numberOfLevels: Int) : TournamentLateRegistration
}

@Serializable
sealed interface TournamentRebuyConfig {
    @Serializable
    @SerialName("Disabled")
    data object Disabled : TournamentRebuyConfig

    @Serializable
    @SerialName("Enabled")
    data class Enabled(
        val rebuyAvailability: RebuyAvailability,
        val rebuyLimit: Int,
        val maxStackForRebuy: Fixed2,
    ) : TournamentRebuyConfig
}

@Serializable
sealed interface RebuyAvailability {
    @Serializable
    @SerialName("Unlimited")
    data object Unlimited : RebuyAvailability

    @Serializable
    @SerialName("NumberOfBlindLevels")
    data class NumberOfBlindLevels(val number: Int) : RebuyAvailability
}

@Serializable
sealed interface TournamentPrizeSettings {
    @Serializable
    @SerialName("Payout")
    data class Payout(
        val payoutStructure: Map<ClosedRange<Int>, Map<Int, Double>>,
    ) : TournamentPrizeSettings
}
