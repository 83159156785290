package studio.lostjoker.smartdealer.serialization

import kotlinx.serialization.KSerializer
import kotlinx.serialization.builtins.ByteArraySerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.uuid.Uuid

/**
 * Version of [kotlinx.serialization.internal.UuidSerializer] that serializes [Uuid]s as byte arrays, which is the optimal way for ProtoBuf.
 */
object UuidByteArraySerializer : KSerializer<Uuid> {
    private val serializer = ByteArraySerializer()
    override val descriptor: SerialDescriptor = SerialDescriptor("kotlin.uuid.Uuid.binary", serializer.descriptor)

    override fun serialize(encoder: Encoder, value: Uuid) {
        val byteArray = value.toByteArray()
        encoder.encodeSerializableValue(serializer, byteArray)
    }

    override fun deserialize(decoder: Decoder): Uuid {
        val byteArray = decoder.decodeSerializableValue(serializer)
        return Uuid.fromByteArray(byteArray)
    }
}
