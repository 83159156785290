package service

import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.client.request.put
import io.ktor.client.request.setBody
import io.ktor.client.statement.readRawBytes
import io.ktor.http.ContentType
import io.ktor.http.HttpStatusCode
import io.ktor.http.contentType
import io.ktor.http.isSuccess
import io.ktor.util.date.GMTDate
import studio.lostjoker.smartdealer.game_management.web.contract.AvatarSource
import studio.lostjoker.smartdealer.game_management.web.contract.AvatarUploadRequest
import studio.lostjoker.smartdealer.game_management.web.contract.GameManagementApiErrorCode
import studio.lostjoker.smartdealer.game_management.web.contract.PlayerAccountResponse

class PlayerAccountService(
    apiBaseUrl: String,
) : BaseApiService() {
    private val playerAccountApiBaseUrl = "$apiBaseUrl/api/account/v1"

    suspend fun getPlayerAccount(playerId: String): PlayerAccountResponse? {
        val response = client.get("${playerAccountApiBaseUrl}/profiles/${playerId}")

        if (response.status.isSuccess()) {
            return response.body()
        } else {
            if (response.status == HttpStatusCode.NotFound) return null
            throw GameManagementApiServiceException(GameManagementApiErrorCode.Unknown)
        }
    }

    fun getPlayerAvatarUrl(playerId: String): String {
        val date = GMTDate().timestamp
        return "${playerAccountApiBaseUrl}/profiles/${playerId}/avatar?t=${date}"
    }

    suspend fun setScreenName(playerId: String, screenName: String): String {
        val response = client.put("${playerAccountApiBaseUrl}/profiles/${playerId}/screen-name") {
            contentType(ContentType.Application.Json)
            setBody(screenName)
        }

        if (response.status.isSuccess()) {
            return response.body()
        } else {
            throw GameManagementApiServiceException(GameManagementApiErrorCode.Unknown) // TODO: replace by ScreenNameNotSet ErrorCode
        }
    }

    suspend fun uploadAvatar(playerId: String, avatarUrl: String) {
        val byteArray = urlToByteArray(avatarUrl) ?: return
        val response = client.put("${playerAccountApiBaseUrl}/profiles/${playerId}/avatar") {
            contentType(ContentType.Application.Json)
            setBody(
                AvatarUploadRequest(
                    avatarSource = AvatarSource.Social,
                    avatarBlob = byteArray
                )
            )
        }

        if (response.status.isSuccess()) {
            return
        } else {
            throw GameManagementApiServiceException(GameManagementApiErrorCode.Unknown) // TODO: replace by AvatarNotUploaded ErrorCode
        }
    }

    suspend fun uploadCustomAvatar(playerId: String, avatarBlob: ByteArray) {
        val response = client.put("${playerAccountApiBaseUrl}/profiles/${playerId}/avatar") {
            contentType(ContentType.Application.Json)
            setBody(
                AvatarUploadRequest(
                    avatarSource = AvatarSource.Custom,
                    avatarBlob = avatarBlob,
                )
            )
        }

        if (response.status.isSuccess()) {
            return
        } else {
            throw GameManagementApiServiceException(GameManagementApiErrorCode.Unknown) // TODO: replace by AvatarNotUploaded ErrorCode
        }
    }


    suspend fun updateEmail(playerId: String, emailAddress: String): String {
        val response = client.put("${playerAccountApiBaseUrl}/profiles/${playerId}/email") {
            contentType(ContentType.Application.Json)
            setBody(emailAddress)
        }

        if (response.status.isSuccess()) {
            return response.body()
        } else {
            throw GameManagementApiServiceException(GameManagementApiErrorCode.Unknown) // TODO: replace by EmailNotUpdated ErrorCode
        }
    }

    private suspend fun urlToByteArray(url: String): ByteArray? {
        return try {
            val response = client.get(url)
            if (response.status.isSuccess()) {
                response.readRawBytes()
            } else {
                // TODO: Handle unsuccessful response (e.g., log error)
                null
            }
        } catch (e: Exception) {
            // TODO: Handle exceptions (e.g., network issues)
            null
        }
    }
}
