@file:UseContextualSerialization(Uuid::class)

package protocol.poker

import kotlinx.datetime.Instant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import protocol.DealtRankedCardPlayerView
import protocol.Player
import kotlin.time.Duration
import kotlin.uuid.Uuid

@Serializable
sealed interface PokerClientEvent

@Serializable
@SerialName("GameStarted")
data class GameStartedEvent(
    val instant: Instant,
) : PokerClientEvent

@Serializable
@SerialName("GamePaused")
data class GamePausedEvent(
    val instant: Instant,
) : PokerClientEvent

@Serializable
@SerialName("GameResumed")
data class GameResumedEvent(
    val instant: Instant,
) : PokerClientEvent

@Serializable
@SerialName("GameEnded")
data class GameEndedEvent(
    val instant: Instant,
): PokerClientEvent

@Serializable
@SerialName("TournamentCreated")
data class TournamentCreatedEvent(
    val hostPlayerId: String,
    val format: TournamentSettings,
    val settings: PokerSettings,
) : PokerClientEvent

@Serializable
@SerialName("TournamentSettingsUpdated")
data class TournamentSettingsUpdatedEvent(
    val format: TournamentSettings,
    val settings: PokerSettings,
    val rebuyRemainingCount: Map<String, Int>,
) : PokerClientEvent

@Serializable
@SerialName("PlayerRegistered")
data class PlayerRegisteredEvent(
    val player: Player,
    val sessionId: Uuid,
) : PokerClientEvent

@Serializable
@SerialName("BlindLevelChanged")
data class BlindLevelChangedEvent(
    val blindLevel: BlindLevel,
    val handCountRange: BlindLevelHandCountRange,
    val timeSlotDuration: Duration,
    val lateRegistrationLevelsLeft: Int,
    val rebuyLevelsLeft: Int,
    val instant: Instant,
) : PokerClientEvent

@Serializable
@SerialName("PlayerEliminated")
data class PlayerEliminatedEvent(
    val sessionId: Uuid,
    val rank: Int,
    val prize: Fixed2,
    val rebuy: Int,
    val finalStack: Fixed2,
) : PokerClientEvent

@Serializable
@SerialName("TableCreated")
data class TableCreatedEvent(
    val tableId: Uuid,
    val settings: PokerSettings,
    val straddle: Boolean,
) : PokerClientEvent

@Serializable
@SerialName("TableSettingsUpdated")
data class TableSettingsUpdatedEvent(
    val tableId: Uuid,
    val settings: PokerSettings,
) : PokerClientEvent

@Serializable
@SerialName("PlayerSeated")
data class PlayerSeatedEvent(
    val tableId: Uuid,
    val player: Player,
    val seat: Int,
    val stack: Fixed2,
) : PokerClientEvent

@Serializable
@SerialName("PlayerSatOut")
data class PlayerSatOutEvent(
    val tableId: Uuid,
    val seat: Int,
    val reason: SittingOutReason,
) : PokerClientEvent

@Serializable
@SerialName("PlayerSatIn")
data class PlayerSatInEvent(
    val tableId: Uuid,
    val seat: Int,
) : PokerClientEvent

@Serializable
@SerialName("PlayerRebuyCompleted")
data class PlayerRebuyCompletedEvent(
    val sessionId: Uuid,
    val amount: Fixed2,
    val rebuyRemainingCount: Int,
) : PokerClientEvent

@Serializable
@SerialName("PlayerStackUpdated")
data class PlayerStackUpdatedEvent(
    val tableId: Uuid,
    val playerId: String,
    val seat: Int,
    val amount: Fixed2,
    val stack: Fixed2,
) : PokerClientEvent

@Serializable
@SerialName("PlayerBlindMissed")
data class PlayerBlindMissedEvent(
    val tableId: Uuid,
    val seat: Int,
    val blindType: BlindType,
) : PokerClientEvent

@Serializable
@SerialName("HandStarted")
data class HandStartedEvent(
    val tableId: Uuid,
    val handId: Uuid,
    val handIndex: Int, // zero-based
    val positions: PositionsFull,
    val settings: PokerSettings,
) : PokerClientEvent

@Serializable
@SerialName("RoundStarted")
data class RoundStartedEvent(
    val roundIndex: Int,
    val potSizeBeforeRound: Fixed2,
    val remainingStacks: List<SeatSnapshot>,
) : PokerClientEvent

@Serializable
@SerialName("ActionPerformed")
data class ActionPerformedEvent(
    val seat: Int,
    val action: PlayerHandAction,
    val requestId: Uuid?,
    val bettingStack: Fixed2,
    val remainingStack: Fixed2,
    val totalPotSize: Fixed2,
    val auto: Boolean,
    val away: Boolean,
) : PokerClientEvent

@Serializable
@SerialName("ActionRequested")
data class ActionRequestedEvent(
    val requestId: Uuid,
    val seat: Int,
    val availableActions: List<PlayerHandAvailableAction>,
    val instant: Instant,
    val timeout: Duration,
) : PokerClientEvent

@Serializable
@SerialName("BlindsPosted")
data object BlindsPostedEvent : PokerClientEvent

@Serializable
@SerialName("PlayerCardsDealt")
data class PlayerCardsDealtEvent(
    val tableId: Uuid,
    val handId: Uuid,
    val seat: Int,
    val cards: List<DealtRankedCardPlayerView>,
) : PokerClientEvent

@Serializable
@SerialName("PlayerCardsRevealed")
data class PlayerCardsRevealedEvent(
    val tableId: Uuid,
    val handId: Uuid,
    val seat: Int,
    val cards: List<DealtRankedCardPlayerView>,
) : PokerClientEvent

@Serializable
@SerialName("CommunityCardsDealt")
data class CommunityCardsDealtEvent(
    val tableId: Uuid,
    val handId: Uuid,
    val cards: List<DealtRankedCardPlayerView>,
    val highHandRanks: Map<Int, RankedHighHand>,
    val lowHandRanks: Map<Int, RankedLowHand>,
) : PokerClientEvent

@Serializable
@SerialName("RoundEnded")
data class RoundEndedEvent(
    val tableId: Uuid,
    val handId: Uuid,
    val roundIndex: Int,
    val seatResults: List<SeatRoundResult>,
    val potContributions: List<Pot>,
) : PokerClientEvent

@Serializable
@SerialName("HandEnded")
data class HandEndedEvent(
    val tableId: Uuid,
    val handId: Uuid,
    val seatResults: List<SeatHandResult>,
    val potShares: List<PotSharePlayerProjection>,
    val pots: List<Pot>,
) : PokerClientEvent

@Serializable
@SerialName("RingCreated")
data class RingCreatedEvent(
    val hostPlayerId: String,
    val format: RingGameSettings,
    val settings: PokerSettings,
) : PokerClientEvent

@Serializable
@SerialName("PlayerBoughtIn")
data class PlayerBoughtInEvent(
    val player: Player,
    val sessionId: Uuid,
) : PokerClientEvent

@Serializable
@SerialName("PlayerToppedUp")
data class PlayerToppedUpEvent(
    val sessionId: Uuid,
    val amount: Fixed2,
) : PokerClientEvent

@Serializable
@SerialName("RingGameSettingsUpdated")
data class RingGameSettingsUpdatedEvent(
    val format: RingGameSettings,
    val settings: PokerSettings,
) : PokerClientEvent

