package studio.lostjoker.smartdealer.domain

import kotlinx.serialization.KSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

@Serializable(with = Suit.Serializer::class)
enum class Suit(
    val char: Char,
) {
    Diamonds('d'),
    Spades('s'),
    Hearts('h'),
    Clubs('c'),
    ;

    companion object {
        val bySingleChar by lazy { entries.associateBy { it.char } }
    }

    object Serializer : KSerializer<Suit> {
        override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("studio.lostjoker.smartdealer.Suit", PrimitiveKind.CHAR)
        override fun deserialize(decoder: Decoder): Suit = Suit.bySingleChar[decoder.decodeChar()]!!
        override fun serialize(encoder: Encoder, value: Suit) = encoder.encodeChar(value.char)
    }
}



@Serializable(with = Rank.Serializer::class)
enum class Rank(
    val char: Char,
) {
    Two('2'),
    Three('3'),
    Four('4'),
    Five('5'),
    Six('6'),
    Seven('7'),
    Eight('8'),
    Nine('9'),
    Ten('T'),
    J('J'),
    Q('Q'),
    K('K'),
    A('A'),
    ;

    companion object {
        val bySingleChar by lazy { entries.associateBy { it.char } }
    }

    object Serializer : KSerializer<Rank> {
        override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("studio.lostjoker.smartdealer.Rank", PrimitiveKind.CHAR)
        override fun deserialize(decoder: Decoder): Rank = Rank.bySingleChar[decoder.decodeChar()]!!
        override fun serialize(encoder: Encoder, value: Rank) = encoder.encodeChar(value.char)
    }
}

@Serializable
sealed interface Card {
    val color: Int

    @Serializable
    @SerialName("Joker")
    data class Joker(override val color: Int) : Card {
        override fun toSingleDeckCompactString() = "Joker"
    }

    @Serializable(with = Ranked.Serializer::class)
    @SerialName("Ranked")
    data class Ranked(val rank: Rank, val suit: Suit, override val color: Int = 0) : Card {
        override fun toSingleDeckCompactString() = "${rank.char}${suit.char}"
        override fun toString() = toSingleDeckCompactString()

        object Serializer : KSerializer<Ranked> {
            override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("studio.lostjoker.smartdealer.Card.Ranked", PrimitiveKind.STRING)
            override fun deserialize(decoder: Decoder): Ranked {
                val (r, s) = decoder.decodeString().toCharArray()
                return Ranked(Rank.bySingleChar[r]!!, Suit.bySingleChar[s]!!)
            }
            override fun serialize(encoder: Encoder, value: Ranked) {
                if (value.color != 0) throw UnsupportedOperationException("Cannot serialize non-zero color deck cards")
                encoder.encodeString("${value.rank.char}${value.suit.char}")
            }
        }
    }

    fun toSingleDeckCompactString(): String
}

data class DealtCard(
    val card: Card,
    val faceUp: Boolean,
)

@Serializable
data class DealtRankedCard(
    val card: Card.Ranked,
    val faceUp: Boolean,
)

sealed interface DealtRankedCardPlayerView {
    data object FaceDown : DealtRankedCardPlayerView

    data class FaceUp(
        val card: Card.Ranked,
    ) : DealtRankedCardPlayerView {
        override fun toString(): String {
            return card.toSingleDeckCompactString()
        }
    }
}


data class Deck(
    val cards: List<Card>,
) {
    operator fun plus(other: Deck): Deck {
        return Deck(this.cards + other.cards)
    }

    fun draw(n: Int): DrawResult {
        if (n > this.cards.size) throw IllegalStateException("Deck has not enough cards: $n")
        return DrawResult(this.cards.subList(0, n), Deck(this.cards.subList(n, this.cards.size)))
    }

    data class DrawResult(val cards: List<Card>, val resultingDeck: Deck)
}

fun generateDeck(color: Int = 0, nJokers: Int = 0, vararg rankRanges: ClosedRange<Rank> = arrayOf(Rank.Two..Rank.A)) = Deck(
    cards = buildList {
        repeat(nJokers) {
            add(Card.Joker(color))
        }
        Rank.entries.forEach { rank ->
            if (rankRanges.any { rankRange -> rankRange.contains(rank) }) {
                Suit.entries.forEach { suit ->
                    add(Card.Ranked(rank, suit, color))
                }
            }
        }
    }
)

typealias DeckShuffler = (deck: Deck) -> Deck

val standardDeckShuffler: DeckShuffler = { deck -> Deck(deck.cards.shuffled()) }
