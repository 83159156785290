@file:AutoConverters(
    [
        AutoConverter(domainClass = PokerVariant::class, protocolClass = protocol.poker.PokerVariant::class),
        AutoConverter(domainClass = HighLowMode::class, protocolClass = protocol.poker.HighLowMode::class),
        AutoConverter(domainClass = BettingLimit::class, protocolClass = protocol.poker.BettingLimit::class),
        AutoConverter(domainClass = PokerSettings::class, protocolClass = protocol.poker.PokerSettings::class),
        AutoConverter(domainClass = LowHandRank::class, protocolClass = protocol.poker.LowHandRank::class),
        AutoConverter(domainClass = HighHandRank::class, protocolClass = protocol.poker.HighHandRank::class),
        AutoConverter(domainClass = BlindLevel::class, protocolClass = protocol.poker.BlindLevel::class),
        AutoConverter(domainClass = BlindLevelHandCountDuration::class, protocolClass = protocol.poker.BlindLevelHandCountDuration::class),
        AutoConverter(domainClass = BlindLevelTimeSlotDuration::class, protocolClass = protocol.poker.BlindLevelTimeSlotDuration::class),
        AutoConverter(domainClass = BlindLevelHandCountRange::class, protocolClass = protocol.poker.BlindLevelHandCountRange::class),
        AutoConverter(domainClass = TournamentSettings::class, protocolClass = protocol.poker.TournamentSettings::class),
        AutoConverter(domainClass = TournamentStartTrigger::class, protocolClass = protocol.poker.TournamentStartTrigger::class),
        AutoConverter(domainClass = BlindProgression::class, protocolClass = protocol.poker.BlindProgression::class),
        AutoConverter(domainClass = TournamentRegistrationSettings::class, protocolClass = protocol.poker.TournamentRegistrationSettings::class),
        AutoConverter(domainClass = TournamentLateRegistration::class, protocolClass = protocol.poker.TournamentLateRegistration::class),
        AutoConverter(domainClass = TournamentRebuyConfig::class, protocolClass = protocol.poker.TournamentRebuyConfig::class),
        AutoConverter(domainClass = RebuyAvailability::class, protocolClass = protocol.poker.RebuyAvailability::class),
        AutoConverter(domainClass = TournamentPrizeSettings::class, protocolClass = protocol.poker.TournamentPrizeSettings::class),
        AutoConverter(domainClass = PositionsFull::class, protocolClass = protocol.poker.PositionsFull::class),
        AutoConverter(domainClass = SeatSnapshot::class, protocolClass = protocol.poker.SeatSnapshot::class),
        AutoConverter(domainClass = PlayerHandAction::class, protocolClass = protocol.poker.PlayerHandAction::class),
        AutoConverter(domainClass = PlayerHandAvailableAction::class, protocolClass = protocol.poker.PlayerHandAvailableAction::class),
        AutoConverter(domainClass = SeatRoundResult::class, protocolClass = protocol.poker.SeatRoundResult::class),
        AutoConverter(domainClass = HighLow::class, protocolClass = protocol.poker.HighLow::class),
        AutoConverter(domainClass = PotSharePlayerProjection::class, protocolClass = protocol.poker.PotSharePlayerProjection::class),
        AutoConverter(domainClass = SeatHandResult::class, protocolClass = protocol.poker.SeatHandResult::class),
        AutoConverter(domainClass = RingGameSettings::class, protocolClass = protocol.poker.RingGameSettings::class),
        AutoConverter(domainClass = RingBuyInSettings::class, protocolClass = protocol.poker.RingBuyInSettings::class),
        AutoConverter(domainClass = SittingOutReason::class, protocolClass = protocol.poker.SittingOutReason::class),
        AutoConverter(domainClass = BlindType::class, protocolClass = protocol.poker.BlindType::class),
    ],
)

package studio.lostjoker.smartdealer.gameplay.protocol.converter

import studio.lostjoker.smartdealer.domain.DealtRankedCardPlayerView
import studio.lostjoker.smartdealer.domain.Fixed2
import studio.lostjoker.smartdealer.domain.poker.HighHandRank
import studio.lostjoker.smartdealer.domain.poker.LowHandRank
import studio.lostjoker.smartdealer.domain.poker.RankedHand
import studio.lostjoker.smartdealer.domain.poker.model.*
import studio.lostjoker.smartdealer.contract.annotation.AutoConverter
import studio.lostjoker.smartdealer.contract.annotation.AutoConverters

fun Fixed2.toProtocol(): protocol.poker.Fixed2 = this.backingValue

fun protocol.poker.Fixed2.toDomain(): Fixed2 = Fixed2.fromBackingValue(this)

fun Pot.toProtocol() = protocol.poker.Pot(
    amount = amount.toProtocol(),
    seats = seats.toList(),
)
fun protocol.poker.Pot.toDomain() = Pot(
    amount = amount.toDomain(),
    seats = seats.toSet(),
)

// converters for RankedHand<HighHandRank>
fun RankedHand<HighHandRank>.toProtocol() = protocol.poker.RankedHighHand(
    cards = cards.map { it.toProtocol() },
    rank = rank.toProtocol(),
)
fun protocol.poker.RankedHighHand.toDomain() = RankedHand(
    cards = cards.map { it.toDomain() },
    rank = rank.toDomain(),
)

// converters for RankedHand<LowHandRank>
fun RankedHand<LowHandRank>.toProtocol() = protocol.poker.RankedLowHand(
    cards = cards.map { it.toProtocol() },
    rank = rank.toProtocol(),
)
fun protocol.poker.RankedLowHand.toDomain() = RankedHand(
    cards = cards.map { it.toDomain() },
    rank = rank.toDomain(),
)

fun Map<Int, Double>.toProtocol() = this

fun Map<Int, Double>.toDomain() = this

fun List<DealtRankedCardPlayerView>.toProtocol() = this.map { it.toProtocol() }

fun List<protocol.DealtRankedCardPlayerView>.toDomain() = this.map { card ->
    when (card) {
        is protocol.DealtRankedCardPlayerView.FaceDown -> card.toDomain()
        is protocol.DealtRankedCardPlayerView.FaceUp -> card.toDomain()
    }
}
