package studio.lostjoker.smartdealer.ui.poker.common.helpers

import studio.lostjoker.smartdealer.ui.poker.enum.BlindSpeed
import studio.lostjoker.smartdealer.ui.poker.enum.RingGameStake
import studio.lostjoker.smartdealer.ui.poker.host_game.RingGameBlindSettings
import studio.lostjoker.smartdealer.ui.poker.host_game.SitAndGoBlindSettings

val ringGameBlindStructure: Map<RingGameStake, List<RingGameBlindSettings>> = mapOf(
    RingGameStake.Micro to listOf(
        RingGameBlindSettings(null, 0.01, 0.02),
        RingGameBlindSettings(null, 0.02, 0.04),
        RingGameBlindSettings(null, 0.05, 0.10),
        RingGameBlindSettings(null, 0.10, 0.20),
        RingGameBlindSettings(null, 0.25, 0.50),
        RingGameBlindSettings(null, 0.30, 0.60),
        RingGameBlindSettings(null, 0.50, 1.00),
        RingGameBlindSettings(null, 0.80, 1.60),
    ),
    RingGameStake.Low to listOf(
        RingGameBlindSettings(null, 1.0, 2.0),
        RingGameBlindSettings(null, 2.0, 4.0),
        RingGameBlindSettings(null, 2.5, 5.0),
        RingGameBlindSettings(null, 3.0, 6.0),
        RingGameBlindSettings(null, 5.0, 10.0),
        RingGameBlindSettings(null, 7.0, 14.0),
        RingGameBlindSettings(null, 8.0, 16.0),
        RingGameBlindSettings(null, 9.0, 18.0),
    ),
    RingGameStake.Medium to listOf(
        RingGameBlindSettings(null, 10.0, 20.0),
        RingGameBlindSettings(null, 15.0, 30.0),
        RingGameBlindSettings(null, 25.0, 50.0),
        RingGameBlindSettings(null, 30.0, 60.0),
        RingGameBlindSettings(null, 50.0, 100.0),
        RingGameBlindSettings(null, 75.0, 150.0),
    ),
    RingGameStake.High to listOf(
        RingGameBlindSettings(null, 100.0, 200.0),
        RingGameBlindSettings(null, 125.0, 250.0),
        RingGameBlindSettings(null, 150.0, 300.0),
        RingGameBlindSettings(null, 200.0, 400.0),
        RingGameBlindSettings(null, 300.0, 600.0),
        RingGameBlindSettings(null, 500.0, 1000.0),
        RingGameBlindSettings(null, 600.0, 1200.0),
        RingGameBlindSettings(null, 700.0, 1400.0),
        RingGameBlindSettings(null, 800.0, 1600.0),
        RingGameBlindSettings(null, 1000.0, 2000.0),
    ),
)

val sitAndGoBlindStructure: Map<BlindSpeed, List<SitAndGoBlindSettings>> = mapOf(
    BlindSpeed.Hyper to listOf(
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 25.0, bigBlind = 50.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 50.0, bigBlind = 100.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 75.0, bigBlind = 150.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 100.0, bigBlind = 200.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 150.0, bigBlind = 300.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 200.0, bigBlind = 400.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 250.0, bigBlind = 500.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 300.0, bigBlind = 600.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 400.0, bigBlind = 800.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 500.0, bigBlind = 1000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 600.0, bigBlind = 1200.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 800.0, bigBlind = 1600.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 1000.0, bigBlind = 2000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 2000.0, bigBlind = 4000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 3000.0, bigBlind = 6000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 4000.0, bigBlind = 8000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 5000.0, bigBlind = 10000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 6000.0, bigBlind = 12000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 8000.0, bigBlind = 16000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 10000.0, bigBlind = 20000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 15000.0, bigBlind = 30000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 20000.0, bigBlind = 40000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 25000.0, bigBlind = 50000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 30000.0, bigBlind = 60000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 40000.0, bigBlind = 80000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 50000.0, bigBlind = 100000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 60000.0, bigBlind = 120000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 80000.0, bigBlind = 160000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 100000.0, bigBlind = 200000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Hyper), hands = findHands(BlindSpeed.Hyper), smallBlind = 120000.0, bigBlind = 240000.0),
    ),
    BlindSpeed.Turbo to listOf(
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 25.0, bigBlind = 50.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 50.0, bigBlind = 100.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 75.0, bigBlind = 150.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 100.0, bigBlind = 200.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 150.0, bigBlind = 300.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 200.0, bigBlind = 400.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 250.0, bigBlind = 500.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 300.0, bigBlind = 600.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 400.0, bigBlind = 800.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 500.0, bigBlind = 1000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 600.0, bigBlind = 1200.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 800.0, bigBlind = 1600.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 1000.0, bigBlind = 2000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 2000.0, bigBlind = 4000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 3000.0, bigBlind = 6000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 4000.0, bigBlind = 8000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 5000.0, bigBlind = 10000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 6000.0, bigBlind = 12000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 8000.0, bigBlind = 16000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 10000.0, bigBlind = 20000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 15000.0, bigBlind = 30000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 20000.0, bigBlind = 40000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 25000.0, bigBlind = 50000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 30000.0, bigBlind = 60000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 40000.0, bigBlind = 80000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 50000.0, bigBlind = 100000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 60000.0, bigBlind = 120000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 80000.0, bigBlind = 160000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 100000.0, bigBlind = 200000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Turbo), hands = findHands(BlindSpeed.Turbo), smallBlind = 120000.0, bigBlind = 240000.0),
    ),
    BlindSpeed.Regular to listOf(
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 25.0, bigBlind = 50.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 50.0, bigBlind = 100.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 75.0, bigBlind = 150.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 100.0, bigBlind = 200.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 150.0, bigBlind = 300.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 200.0, bigBlind = 400.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 250.0, bigBlind = 500.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 300.0, bigBlind = 600.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 400.0, bigBlind = 800.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 500.0, bigBlind = 1000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 600.0, bigBlind = 1200.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 800.0, bigBlind = 1600.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 1000.0, bigBlind = 2000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 2000.0, bigBlind = 4000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 3000.0, bigBlind = 6000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 4000.0, bigBlind = 8000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 5000.0, bigBlind = 10000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 6000.0, bigBlind = 12000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 8000.0, bigBlind = 16000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 10000.0, bigBlind = 20000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 15000.0, bigBlind = 30000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 20000.0, bigBlind = 40000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 25000.0, bigBlind = 50000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 30000.0, bigBlind = 60000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 40000.0, bigBlind = 80000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 50000.0, bigBlind = 100000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 60000.0, bigBlind = 120000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 80000.0, bigBlind = 160000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 100000.0, bigBlind = 200000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Regular), hands = findHands(BlindSpeed.Regular), smallBlind = 120000.0, bigBlind = 240000.0),
    ),
    BlindSpeed.Slow to listOf(
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 25.0, bigBlind = 50.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 50.0, bigBlind = 100.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 75.0, bigBlind = 150.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 100.0, bigBlind = 200.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 150.0, bigBlind = 300.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 200.0, bigBlind = 400.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 250.0, bigBlind = 500.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 300.0, bigBlind = 600.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 400.0, bigBlind = 800.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 500.0, bigBlind = 1000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 600.0, bigBlind = 1200.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 800.0, bigBlind = 1600.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 1000.0, bigBlind = 2000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 2000.0, bigBlind = 4000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 3000.0, bigBlind = 6000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 4000.0, bigBlind = 8000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 5000.0, bigBlind = 10000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 6000.0, bigBlind = 12000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 8000.0, bigBlind = 16000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 10000.0, bigBlind = 20000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 15000.0, bigBlind = 30000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 20000.0, bigBlind = 40000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 25000.0, bigBlind = 50000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 30000.0, bigBlind = 60000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 40000.0, bigBlind = 80000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 50000.0, bigBlind = 100000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 60000.0, bigBlind = 120000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 80000.0, bigBlind = 160000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 100000.0, bigBlind = 200000.0),
        SitAndGoBlindSettings(minutes = findMinutes(BlindSpeed.Slow), hands = findHands(BlindSpeed.Slow), smallBlind = 120000.0, bigBlind = 240000.0),
    ),
)

internal fun findMinutes(blindSpeed: BlindSpeed): Int {
    return when (blindSpeed) {
        BlindSpeed.Slow -> 15
        BlindSpeed.Regular -> 10
        BlindSpeed.Turbo -> 5
        BlindSpeed.Hyper -> 3
    }
}

internal fun findHands(blindSpeed: BlindSpeed): Int {
    return when (blindSpeed) {
        BlindSpeed.Slow -> 10
        BlindSpeed.Regular -> 8
        BlindSpeed.Turbo -> 5
        BlindSpeed.Hyper -> 3
    }
}

internal fun findRingGameNearestBlind(blindOptions: RingGameStake, sliderPosition: Int): RingGameBlindSettings {
    val blindStructure = ringGameBlindStructure[blindOptions] ?: throw Exception("Invalid blind structure")
    return blindStructure[sliderPosition - 1]
}


