package service

import io.ktor.client.HttpClient
import io.ktor.client.plugins.HttpRequestRetry
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.serialization.kotlinx.json.json
import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.SerializersModule
import studio.lostjoker.smartdealer.serialization.ByteArrayBase64Serializer

abstract class BaseApiService {
    protected val client = HttpClient {
        install(ContentNegotiation) {
            json(
                Json {
                    serializersModule = SerializersModule {
                        contextual(ByteArray::class, ByteArrayBase64Serializer)
                    }
                },
            )
        }
        install(HttpRequestRetry) {
            retryOnException(maxRetries = 5, retryOnTimeout = true)
            retryOnServerErrors(maxRetries = 5)
            exponentialDelay()
        }
    }
}
