package studio.lostjoker.smartdealer.ui.poker.devices.player.components

import androidx.compose.foundation.background
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.displayCutout
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.outlined.ArrowBack
import androidx.compose.material3.Button
import androidx.compose.material3.CenterAlignedTopAppBar
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.platform.LocalSoftwareKeyboardController
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.poker_settings_sng
import smartdealer.appshared.generated.resources.poker_settings_update
import studio.lostjoker.smartdealer.domain.poker.model.BlindLevel
import studio.lostjoker.smartdealer.domain.poker.model.PokerSettings
import studio.lostjoker.smartdealer.domain.poker.model.RebuyAvailability
import studio.lostjoker.smartdealer.domain.poker.model.BlindProgression
import studio.lostjoker.smartdealer.domain.poker.model.TournamentLateRegistration
import studio.lostjoker.smartdealer.domain.poker.model.TournamentRebuyConfig
import studio.lostjoker.smartdealer.domain.poker.model.TournamentRegistrationSettings
import studio.lostjoker.smartdealer.domain.poker.model.TournamentSettings
import studio.lostjoker.smartdealer.domain.toFixed2
import studio.lostjoker.smartdealer.ui.common.dialog.UpdateGameSettingsConfirmationDialog
import studio.lostjoker.smartdealer.ui.components.TopAppBarActionButton
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.Player
import studio.lostjoker.smartdealer.ui.poker.enum.ActionTimeout
import studio.lostjoker.smartdealer.ui.poker.host_game.LateRegistrationConfig
import studio.lostjoker.smartdealer.ui.poker.host_game.RebuyConfig
import studio.lostjoker.smartdealer.ui.poker.host_game.SitAndGoSettings
import studio.lostjoker.smartdealer.ui.poker.host_game.components.ActionTimeoutSelector
import studio.lostjoker.smartdealer.ui.poker.host_game.components.BuyInSelector
import studio.lostjoker.smartdealer.ui.poker.host_game.components.LateRegistrationSelector
import studio.lostjoker.smartdealer.ui.poker.host_game.components.RebuySelector
import studio.lostjoker.smartdealer.ui.poker.host_game.components.TableSizeSelector
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun TournamentSettings(
    players: List<Player>,
    currentBlindLevel: BlindLevel,
    tournamentSettings: TournamentSettings,
    pokerSettings: PokerSettings,
    onUpdateTournamentSettings: (tournamentSettings: TournamentSettings, pokerSettings: PokerSettings) -> Unit = { _, _ -> },
    onCloseTournamentSettings: () -> Unit = {},
) {
    val focusManager = LocalFocusManager.current
    val keyboardController = LocalSoftwareKeyboardController.current

    var openUpdateGameSettingsConfirmationDialog by remember { mutableStateOf(false) }
    var openKeyboardBackground by remember { mutableStateOf(false) }

    val currentState = TournamentSettingsState(
        buyIn = when (val registration = tournamentSettings.registration) {
            is TournamentRegistrationSettings.ImplicitBuyIn -> registration.buyIn.asDouble().toInt()
        },
        tableSize = pokerSettings.tableSize,
        actionTimeout = ActionTimeout.entries.single { it.seconds == pokerSettings.actionTimeout },
        lateRegistrationConfig = when (val lateRegistration = tournamentSettings.lateRegistration) {
            is TournamentLateRegistration.Disabled -> LateRegistrationConfig(numberOfLevels = 0)
            is TournamentLateRegistration.BlindLevels -> LateRegistrationConfig(numberOfLevels = lateRegistration.numberOfLevels)
        },
        rebuyConfig = when (val rebuy = tournamentSettings.rebuy) {
            is TournamentRebuyConfig.Disabled -> RebuyConfig()
            is TournamentRebuyConfig.Enabled -> RebuyConfig(
                checked = true,
                numberOfRebuys = rebuy.rebuyLimit,
                numberOfLevels = when (val rebuyAvailability = rebuy.rebuyAvailability) {
                    is RebuyAvailability.NumberOfBlindLevels -> rebuyAvailability.number
                    is RebuyAvailability.Unlimited -> Int.MAX_VALUE
                },
            )
        },
    )

    var newState by remember { mutableStateOf(currentState) }

    Column(
        modifier = Modifier
            .background(PokerAppTheme.colors.background)
            .fillMaxSize(),
    ) {
        CenterAlignedTopAppBar(
            windowInsets = WindowInsets.displayCutout,
            title = {
                Text(
                    style = PokerAppTheme.typography.titleLarge,
                    text = stringResource(Res.string.poker_settings_sng),
                )
            },
            navigationIcon = {
                TopAppBarActionButton(
                    imageVector = Icons.AutoMirrored.Outlined.ArrowBack,
                    description = "Return",
                ) {
                    onCloseTournamentSettings()
                }
            },
            actions = {},
        )
        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(vertical = 8.dp),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            Column(
                modifier = Modifier.fillMaxSize().weight(1f),
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                Column(
                    modifier = Modifier
                        .fillMaxSize()
                        .padding(horizontal = 16.dp)
                        .verticalScroll(rememberScrollState()),
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.spacedBy(PokerAppTheme.dimensions.grid_1_5, Alignment.Top),
                ) {
                    Spacer(modifier = Modifier.height(PokerAppTheme.dimensions.grid_0_5))

                    TableSizeSelector(newState.tableSize, players.maxOf { it.seat } + 1, 10) { size ->
                        newState = newState.copy(tableSize = size)
                    }

                    ActionTimeoutSelector(
                        actionTimeout = newState.actionTimeout,
                        onChangeActionTimeout = { actionTimeout ->
                            newState = newState.copy(actionTimeout = actionTimeout)
                        }
                    )

                    BuyInSelector(
                        buyIn = newState.buyIn,
                        onChangeBuyIn = { buyIn ->
                            newState = newState.copy(buyIn = buyIn)
                        },
                        textFieldFocused = { isFocused ->
                            openKeyboardBackground = isFocused
                        },
                    )

                    val sitAndGoSettings = SitAndGoSettings().copy(
                        lateRegistration = newState.lateRegistrationConfig,
                        rebuyConfig = newState.rebuyConfig,
                    )

                    LateRegistrationSelector(
                        sitAndGoSettings,
                        minBlindLevel = when (val blindProgression = pokerSettings.blindProgression) {
                            is BlindProgression.HandCount -> blindProgression.levels.indexOfFirst { it.blindLevel == currentBlindLevel } + 1
                            else -> TODO("Implement remaining branches")
                        },
                        maxBlindLevel = when (val blindProgression = pokerSettings.blindProgression) {
                            is BlindProgression.HandCount -> blindProgression.levels.count()
                            else -> TODO("Implement remaining branches")
                        },
                        onChangeLateRegistrationConfig = { lateRegistration ->
                            newState = newState.copy(lateRegistrationConfig = lateRegistration)
                        },
                        onChangeRebuyConfig = { rebuyConfig ->
                            newState = newState.copy(rebuyConfig = rebuyConfig)
                        },
                    )

                    val maxActualRebuys = players.maxOf { it.rebuy }

                    RebuySelector(
                        sitAndGoSettings,
                        minRebuys = if (maxActualRebuys > 6) Int.MAX_VALUE else maxActualRebuys + 1,
                        onChangeRebuyConfig = { rebuyConfig ->
                            newState = newState.copy(rebuyConfig = rebuyConfig)
                        },
                    )
                }
            }
            Button(
                modifier = Modifier
                    .fillMaxWidth(.8f)
                    .padding(bottom = PokerAppTheme.dimensions.grid_1_5),
                onClick = { openUpdateGameSettingsConfirmationDialog = true },
                enabled = currentState != newState,
            ) {
                Text(
                    text = stringResource(Res.string.poker_settings_update),
                    style = PokerAppTheme.typography.titleMedium,
                )
            }
        }

        if (openUpdateGameSettingsConfirmationDialog) {
            UpdateGameSettingsConfirmationDialog(
                closeDialog = { openUpdateGameSettingsConfirmationDialog = false },
                updateGameSettings = {
                    // TODO: Include FB Analytics
                    onUpdateTournamentSettings(
                        tournamentSettings.copy(
                            maxPlayers = newState.tableSize,
                            registration = TournamentRegistrationSettings.ImplicitBuyIn(newState.buyIn.toFixed2()),
                            lateRegistration = if (newState.lateRegistrationConfig.numberOfLevels == 0) {
                                TournamentLateRegistration.Disabled
                            } else {
                                TournamentLateRegistration.BlindLevels(
                                    numberOfLevels = newState.lateRegistrationConfig.numberOfLevels,
                                )
                            },
                            rebuy = if (newState.rebuyConfig.checked) {
                                TournamentRebuyConfig.Enabled(
                                    rebuyAvailability = RebuyAvailability.NumberOfBlindLevels(newState.lateRegistrationConfig.numberOfLevels),
                                    rebuyLimit = if (newState.rebuyConfig.numberOfRebuys == 6) {
                                        Int.MAX_VALUE
                                    } else {
                                        newState.rebuyConfig.numberOfRebuys
                                    },
                                    maxStackForRebuy = 0.toFixed2(),
                                )
                            } else {
                                TournamentRebuyConfig.Disabled
                            },
                        ),
                        pokerSettings.copy(
                            tableSize = newState.tableSize,
                            actionTimeout = newState.actionTimeout.seconds,
                        ),
                    )
                    onCloseTournamentSettings()
                },
            )
        }

        // FIXME: Work around while Compose Multiplatform does not allow to hide keyboard on touch outside
        if (openKeyboardBackground) {
            Box(
                modifier = Modifier
                    .fillMaxSize()
                    .pointerInput(Unit) {
                        detectTapGestures(
                            onTap = {
                                keyboardController?.hide()
                                focusManager.clearFocus()
                            },
                        )
                    },
            ) {
                // Keyboard background to dismiss on touch outside
            }
        }
    }
}

data class TournamentSettingsState(
    val buyIn: Int,
    val tableSize: Int,
    val actionTimeout: ActionTimeout,
    val lateRegistrationConfig: LateRegistrationConfig,
    val rebuyConfig: RebuyConfig,
)
