package studio.lostjoker.smartdealer.domain

import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline
import kotlin.math.round

@Serializable
@JvmInline
value class Fixed2 private constructor(val backingValue: Long) : Comparable<Fixed2> {
    constructor(float: Float) : this(round(float * 100).toLong())
    constructor(double: Double) : this(round(double * 100).toLong())
    constructor(int: Int) : this(int * 100L)

    fun asDouble() = backingValue.toDouble() / 100

    fun asFloat() = backingValue.toFloat() / 100

    operator fun plus(other: Fixed2) = Fixed2(this.backingValue + other.backingValue)
    operator fun minus(other: Fixed2) = Fixed2(this.backingValue - other.backingValue)
    operator fun div(other: Fixed2) = this.backingValue / other.backingValue
    operator fun div(other: Int) = Fixed2(this.backingValue / other)
    operator fun times(other: Int) = Fixed2(this.backingValue * other)

    fun roundTo(precision: Fixed2) = when (precision) {
        minPrecision -> this
        intPrecision -> Fixed2(this.backingValue / 100 * 100)
        else -> throw IllegalStateException("Precision not supported: $precision")
    }

    override operator fun compareTo(other: Fixed2) = this.backingValue.compareTo(other.backingValue)

    override fun toString(): String {
        return "${asDouble()}"
    }

    companion object {
        val minPrecision = fromBackingValue(1L)
        val intPrecision = fromBackingValue(100L)
        fun fromBackingValue(r: Long) = Fixed2(r)
    }
}

fun minOf(a: Fixed2, b: Fixed2): Fixed2 {
    return if (a < b) return a else b
}

fun maxOf(a: Fixed2, b: Fixed2): Fixed2 {
    return if (a > b) return a else b
}

fun Float.toFixed2(): Fixed2 = Fixed2(this)

fun Double.toFixed2(): Fixed2 = Fixed2(this)

fun Int.toFixed2(): Fixed2 = Fixed2(this)

inline fun <T> Iterable<T>.sumOf(selector: (T) -> Fixed2): Fixed2 {
    var sum: Fixed2 = 0.toFixed2()
    for (element in this) {
        sum += selector(element)
    }
    return sum
}

inline fun <T> Iterable<T>.maxOf(selector: (T) -> Fixed2): Fixed2 {
    val iterator = iterator()
    if (!iterator.hasNext()) throw NoSuchElementException()
    var maxValue = selector(iterator.next())
    while (iterator.hasNext()) {
        val v = selector(iterator.next())
        maxValue = maxOf(maxValue, v)
    }
    return maxValue
}

inline fun <T> Iterable<T>.maxOfOrNull(selector: (T) -> Fixed2): Fixed2? {
    val iterator = iterator()
    if (!iterator.hasNext()) return null
    var maxValue = selector(iterator.next())
    while (iterator.hasNext()) {
        val v = selector(iterator.next())
        maxValue = maxOf(maxValue, v)
    }
    return maxValue
}
