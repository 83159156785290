package studio.lostjoker.smartdealer.ui.poker.devices.table

import androidx.lifecycle.Lifecycle
import cafe.adriel.voyager.core.model.ScreenModel
import cafe.adriel.voyager.core.model.screenModelScope
import dev.gitlive.firebase.Firebase
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import service.PlayerAccountService
import service.poker.TableDeviceSessionService
import studio.lostjoker.smartdealer.domain.poker.game.projection.BuyInBotCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.DealHandCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.PauseGameCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.PokerTableCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.RegisterBotCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.ResumeGameCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.StartGameCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.TerminateRingCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.UpdateTournamentSettingsCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.UpdateRingGameSettingsCommand
import studio.lostjoker.smartdealer.domain.poker.model.PokerSettings
import studio.lostjoker.smartdealer.domain.poker.model.RingGameSettings
import studio.lostjoker.smartdealer.domain.poker.model.TournamentSettings
import studio.lostjoker.smartdealer.firebase.FirebaseAnalytics
import studio.lostjoker.smartdealer.firebase.FirebaseParamsBuilder
import studio.lostjoker.smartdealer.firebase.analytics
import studio.lostjoker.smartdealer.game_management.web.contract.PlayerAccountResponse
import studio.lostjoker.smartdealer.helpers.connectSessionService
import studio.lostjoker.smartdealer.notification.NotificationService
import studio.lostjoker.smartdealer.preferences.PreferenceDelegate
import studio.lostjoker.smartdealer.preferences.PreferenceEnums
import studio.lostjoker.smartdealer.ui.poker.devices.PokerViewModel
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.WinningHandDetails
import studio.lostjoker.smartdealer.ui.poker.enum.BottomSheetState
import studio.lostjoker.smartdealer.ui.poker.enum.CardBackStyle
import studio.lostjoker.smartdealer.ui.poker.enum.CardLayout
import studio.lostjoker.smartdealer.ui.poker.enum.CardStyle
import studio.lostjoker.smartdealer.ui.poker.enum.Device
import studio.lostjoker.smartdealer.ui.poker.enum.DeviceMode
import studio.lostjoker.smartdealer.ui.poker.enum.TableFelt
import kotlin.uuid.Uuid

class TableDeviceViewModel(
    override val userId: String,
    val gameId: Uuid,
    val gameCode: String,
    val deviceMode: DeviceMode,
    notificationService: NotificationService,
    gameplayBaseUrl: String,
    apiBaseUrl: String,
    private val preferenceDelegate: PreferenceDelegate,
) : PokerViewModel(notificationService), ScreenModel {

    val analytics = Firebase.analytics

    val state = _state.asStateFlow()

    private val tableSessionService = TableDeviceSessionService(
        gameplayBaseUrl = gameplayBaseUrl,
        gameId = gameId,
        playerId = userId,
    )

    private val playerAccountService = PlayerAccountService(apiBaseUrl)

    init {
        screenModelScope.connectSessionService(
            sessionService = tableSessionService,
            eventReducer = ::updateStateOnEvent,
            snapshotHandler = ::updateStateOnSnapshot,
            connecting = { connecting -> _state.update { s -> s.copy(connecting = connecting) } },
            error = { println("Error connecting to game session"); it.printStackTrace() }, // TODO show unrecoverable error message
        )

        val tableFelt = TableFelt.entries[preferenceDelegate.getInt(PreferenceEnums.InGame.TableFelt.key)]
        val cardLayout = CardLayout.entries[preferenceDelegate.getInt(PreferenceEnums.InGame.CardLayout.key)]
        val cardStyle = CardStyle.entries[preferenceDelegate.getInt(PreferenceEnums.InGame.CardStyle.key)]
        val cardBackStyle = CardBackStyle.entries[preferenceDelegate.getInt(PreferenceEnums.InGame.CardBackStyle.key)]
        // These preferences always reset to default value
        preferenceDelegate.putBoolean(PreferenceEnums.InGame.VerticalDisplay.key, false)

        _state.update {
            it.copy(
                userId = userId,
                gameId = gameId,
                gameCode = gameCode,
                deviceMode = deviceMode,
                settings = it.settings.copy(
                    tableFelt = tableFelt,
                    cardLayout = cardLayout,
                    cardStyle = cardStyle,
                    cardBackStyle = cardBackStyle,
                    verticalDisplay = false,
                ),
            )
        }
    }

    fun changeVerticalDisplay(verticalDisplay: Boolean) {
        preferenceDelegate.putBoolean(PreferenceEnums.InGame.VerticalDisplay.key, verticalDisplay)
        analytics.logEvent(
            FirebaseAnalytics.Event.CHANGE_GAME_SETTINGS,
            listOf(
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.PLAYER_ID, userId),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.DEVICE_TYPE, Device.Table.toString()),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.VERTICAL_DISPLAY, verticalDisplay.toString()),
            ),
        )
        _state.update {
            it.copy(
                settings = it.settings.copy(
                    verticalDisplay = verticalDisplay,
                ),
            )
        }
    }

    fun changeTableFelt(tableFelt: TableFelt) {
        preferenceDelegate.putInt(PreferenceEnums.InGame.TableFelt.key, tableFelt.ordinal)
        analytics.logEvent(
            FirebaseAnalytics.Event.CHANGE_GAME_SETTINGS,
            listOf(
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.PLAYER_ID, userId),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.DEVICE_TYPE, Device.Table.toString()),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.TABLE_FELT, tableFelt.toString()),
            ),
        )
        _state.update {
            it.copy(
                settings = it.settings.copy(
                    tableFelt = tableFelt,
                ),
            )
        }
    }

    fun changeCardStyle(cardStyle: CardStyle) {
        preferenceDelegate.putInt(PreferenceEnums.InGame.CardStyle.key, cardStyle.ordinal)
        analytics.logEvent(
            FirebaseAnalytics.Event.CHANGE_GAME_SETTINGS,
            listOf(
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.PLAYER_ID, userId),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.DEVICE_TYPE, Device.Table.toString()),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.CARD_STYLE, cardStyle.toString()),
            ),
        )
        _state.update {
            it.copy(
                settings = it.settings.copy(
                    cardStyle = cardStyle,
                ),
            )
        }
    }

    fun changeCardBackStyle(cardBackStyle: CardBackStyle) {
        preferenceDelegate.putInt(PreferenceEnums.InGame.CardBackStyle.key, cardBackStyle.ordinal)
        analytics.logEvent(
            FirebaseAnalytics.Event.CHANGE_GAME_SETTINGS,
            listOf(
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.PLAYER_ID, userId),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.DEVICE_TYPE, Device.Table.toString()),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.CARD_BACK_STYLE, cardBackStyle.toString()),
            ),
        )
        _state.update {
            it.copy(
                settings = it.settings.copy(
                    cardBackStyle = cardBackStyle,
                ),
            )
        }
    }

    fun changeBottomSheetState(bottomSheetState: BottomSheetState?) {
        _state.update {
            it.copy(
                bottomSheetState = bottomSheetState,
            )
        }
    }

    private fun sendCommand(command: PokerTableCommand) {
        screenModelScope.launch {
            try {
                tableSessionService.send(command)
            } catch (_: Exception) {
                _state.update {
                    it.copy(
                        showCommandErrorMessage = true,
                    )
                }
            }
        }
    }

    fun resetShowCommandErrorMessage() {
        _state.update {
            it.copy(
                showCommandErrorMessage = false,
            )
        }
    }

    fun updateTournamentSettings(tournamentSettings: TournamentSettings, pokerSettings: PokerSettings) {
        sendCommand(
            UpdateTournamentSettingsCommand(
                tournamentSettings = tournamentSettings,
                pokerSettings = pokerSettings,
            ),
        )
    }

    fun updateRingGameSettings(ringGameSettings: RingGameSettings, pokerSettings: PokerSettings) {
        sendCommand(
            UpdateRingGameSettingsCommand(
                ringGameSettings = ringGameSettings,
                pokerSettings = pokerSettings,
            ),
        )
    }

    fun registerBot() {
        val numberOfBots = _state.value.players.count { it.bot }
        sendCommand(
            RegisterBotCommand(
                botId = "bot-${numberOfBots + 1}",
                sessionId = Uuid.random(),
            ),
        )
    }

    fun buyInBot() {
        val numberOfBots = _state.value.players.count { it.bot }
        sendCommand(
            BuyInBotCommand(
                botId = "bot-${numberOfBots + 1}",
                sessionId = Uuid.random(),
            ),
        )
    }

    fun startTournament() {
        analytics.logEvent(
            FirebaseAnalytics.Event.START_GAME,
            listOf(
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.PLAYER_ID, userId),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_ID, gameId.toString()),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_CODE, gameCode),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.TABLE_ID, _state.value.tableId.toString()),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.DEVICE_TYPE, Device.Table.toString()),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.NUMBER_OF_PLAYERS, _state.value.players.count { !it.bot }.toString()),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.NUMBER_OF_BOTS, _state.value.players.count { it.bot }.toString()),
            ),
        )
        sendCommand(
            StartGameCommand,
        )
    }

    fun pauseTournament() {
        analytics.logEvent(
            FirebaseAnalytics.Event.PAUSE_GAME,
            listOf(
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.PLAYER_ID, userId),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_ID, gameId.toString()),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_CODE, gameCode),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.DEVICE_TYPE, Device.Table.toString()),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.TABLE_ID, _state.value.tableId.toString()),
            ),
        )
        sendCommand(
            PauseGameCommand,
        )
    }

    fun resumeTournament() {
        analytics.logEvent(
            FirebaseAnalytics.Event.RESUME_GAME,
            listOf(
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.PLAYER_ID, userId),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_ID, gameId.toString()),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_CODE, gameCode),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.DEVICE_TYPE, Device.Table.toString()),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.TABLE_ID, state.value.tableId.toString()),
            ),
        )
        sendCommand(
            ResumeGameCommand,
        )
    }

    fun deal() {
        val tableId = _state.value.tableId
        if (tableId != null) {
            sendCommand(
                DealHandCommand(tableId),
            )
        }
    }

    fun updatePlayerAccount(playerId: String, bot: Boolean) {
        screenModelScope.launch {
            val playerAccount = if (!bot) fetchPlayerAccount(playerId) else null
            val screenName = if (!bot) playerAccount?.screenName ?: "(login again)" else playerId
            val avatarUrl = if (!bot) playerAccountService.getPlayerAvatarUrl(playerId) else null
            _state.update {
                it.copy(
                    players = it.players.map { player ->
                        if (player.playerId == playerId) {
                            player.copy(
                                screenName = screenName,
                                avatarUrl = avatarUrl,
                                emailAddress = playerAccount?.emailAddress,
                            )
                        } else {
                            player
                        }
                    },
                )
            }
        }
    }

    fun updatePaymentTransfer(playerId: String, bot: Boolean) {
        screenModelScope.launch {
            val playerAccount = if (!bot) fetchPlayerAccount(playerId) else null
            val screenName = if (!bot) playerAccount?.screenName ?: "(login again)" else playerId
            _state.update {
                it.copy(
                    payments = it.payments.map { payment ->
                        if (payment.debtorPlayerId == playerId) {
                            payment.copy(debtorScreenName = screenName)
                        } else if (payment.creditorPlayerId == playerId) {
                            payment.copy(creditorScreenName = screenName)
                        } else {
                            payment
                        }
                    }
                )
            }
        }
    }

    private suspend fun fetchPlayerAccount(playerId: String): PlayerAccountResponse? {
        return playerAccountService.getPlayerAccount(playerId)
    }

    fun updateWinningHandDetails(winningHandDetails: WinningHandDetails) {
        screenModelScope.launch {
            _state.update {
                it.copy(
                    winningHandDetails = winningHandDetails,
                )
            }
        }
    }

    fun terminateGame() {
        sendCommand(
            TerminateRingCommand,
        )
    }

    fun leaveGame() {
        analytics.logEvent(
            FirebaseAnalytics.Event.LEAVE_GAME,
            listOf(
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.PLAYER_ID, userId),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_ID, gameId.toString()),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_CODE, gameCode),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.DEVICE_TYPE, Device.Table.toString()),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.TABLE_ID, _state.value.tableId.toString()),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_STARTED, _state.value.gameStarted.toString()),
                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_ENDED, _state.value.gameEnded.toString()),
            ),
        )
        println("leaving game")
    }

    override fun onDispose() {
        println("disposing $this")
        screenModelScope.launch {
            closeSession()
        }
    }

    private suspend fun closeSession() {
        println("Closing session ${tableSessionService.id} for game $gameId...")
        tableSessionService.close()
        tableSessionService.finalize()
    }

    fun updateApplicationState(applicationState: Lifecycle.State) {
        _state.update {
            it.copy(
                applicationState = applicationState,
            )
        }
    }
}
